import { registerService } from '../../services';
/**
 * Initial state
 */
const state = {
  objectRegiter: {}
};

/**
 * Getters
 * @type {import('vuex/types').GetterTree<typeof state>}
 */
const getters = {
  getObjectRegiter(state) {
    return state.objectRegiter;
  },
};

/**
 * Mutations
 * @type {import('vuex/types').MutationTree<typeof state>}
 */
const mutations = {
  SET_OBJECT_Register(state, payload) {
    state.objectRegiter = payload;
  },
};

/**
 * Actions
 * @type {import('vuex/types').ActionTree<typeof state>}
 */
const actions = {
  // fetchCountry: async ({ commit }, option) => {
  //   countryService.ListCountry(option).then((response) => {
  //     commit('SET_COUNTRY', response.data);
  //   });
  // },
};

// Export module
export default { state, getters, mutations, actions };
