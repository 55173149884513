/**
 * Inject Axios into Vue component
 */
// https://github.com/mzabriskie/axios
// https://juejin.im/entry/587599388d6d810058a7a41a
// https://www.mmxiaowu.com/article/589af8cde9be1c5b21ef8e9c

import { axios } from '../utils';
import router from '../router';
export default (app) => {
  // mount the axios to Vue component instance
  app.config.globalProperties.$axios = axios;
  axios.interceptors.response.use(
    function (response) {
      //  localStorage.setItem('lastTime', moment().utc().format("YYYY-MM-DD HH:mm:ss"))
      return response;
    },
    // function (error) {
    //   if (error.response.status === 401) {
    //     router.replace({ name: 'login' });
    //   }
    //   console.log(error.response.status);
    // }
  );
  // app
};
