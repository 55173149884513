import { CHANGE_SESSION } from './mutation-types';

/**
 * @type {import('vuex/types').MutationTree<typeof import('./state').default>}
 */
const mutations = {
  [CHANGE_SESSION]: (state, session) => {
    // TODO: new session mixin
    Object.assign(state.session, session);
  },
  SET_NAME(state, payload) {
    state.name = payload;
  },
  SET_legal_url(state, payload) {
  state.legal_url=payload;
  }
};


export default mutations;
