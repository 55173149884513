import { storage } from '../utils';

export default {
  /**
   * session
   * @type {object}
   */
  session: storage.get('session') ?? {},
  name:{},
  legal_url:''

};
