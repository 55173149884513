import { createStore } from 'vuex';

// // Make sure state writeable
// import * as state from './state' // prop readonly
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import modules from './modules';
import createPersistedState from 'vuex-persistedstate';
const strict = import.meta.env.DEV;

/**
 * @type {import('vuex/types').Store<typeof import('./state').default>}
 */

const store = createStore({
  state,
  getters,
  mutations,
  actions,
  modules,
  strict,
  plugins: [createPersistedState()],
});
//const store = createStore({ state, getters, mutations, actions, modules, plugins, strict })
export default store;

// Hot module replacement
if (import.meta.hot) {
  import.meta.hot.accept(
    [
      './getters',
      './mutations',
      './actions',
      './modules/register',

    ],
    () => {
      store.hotUpdate({
        getters: require('./getters'),
        mutations: require('./mutations'),
        actions: require('./actions'),
        modules: {
          register: require('./modules/register')
        },
      });
    }
  );
}
