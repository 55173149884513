export default [
  // - Dashboard
  {
    name: 'home',
    path: 'home',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.homeName', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/HomePage.vue'),
  },
  {
    name: 'learners-moduration',
    path: 'learners-moderation',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.learnersModuration', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/moduration/LearnersModuration.vue'),
  },
  {
    name: 'learners-informations',
    path: 'learners-information/:id',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.learnersInformation', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/moduration/LearnersInformations.vue'),
  },
  {
    name: 'team-manager',
    path: 'team-manager',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.teamManager', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/manager/Index.vue'),
  },

  {
    name: 'update-learners-informations',
    path: 'update-learners-information/:id',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.learnersInformation', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/moduration/UpdateLearnersInformations.vue'),
  },
  {
    name: 'show-learners-informations',
    path: 'show-learners-information/:id',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.learnersInformation', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/moduration/ShowLearnersInformations.vue'),
  },
  {
    name: 'archived-learners',
    path: 'archived-learners',
    // component: () => import('../views/HomePage.vue'),
    meta: {
      breadcrumbs: [
        { title: 'pages.teamManager', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/archived/Index.vue'),
  },

  {
    name:'my-points-sales',
    path:'my-points-sales',
    meta: {
      breadcrumbs: [
        { title: 'pages.teamManager', disabled: false, to: '/' },
        {
          title: 'pages',
          disabled: true,
          to: '',
        },
      ],
      title: 'pages',
    },
    component: () => import('../views/point-sales/Index.vue'),
  },
];
