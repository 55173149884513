// dashboard routes
import RegisterRoutes from './RegisterRoutes';
import mainRoutes from './MainRoutes';
import errorRoutes from './ErrorRoutes';

import AppLayout from '../views/layouts/AppLayout.vue';
import RegisterLayout from '../views/layouts/RegisterLayout.vue';
const routes = [
  // ## login page
  {
    path: '/',
    meta: { requireAuth: false },
    component: RegisterLayout,
    children: [...RegisterRoutes],
  },
  // ## main page
  {
    path: '/',
    meta: { requireAuth: true, loading: false },
    component: AppLayout,
    children: [...mainRoutes],
  },

  // ## error pages
  {
    path: '/error/',

    component: () => import('../views/layouts/ErrorsLayout.vue'),
    children: errorRoutes,
  },
  
  // ## not found page
  {
    path: '/:pathMatch(.*)*',
    component: () => import('../views/errors/ErrorNotFound.vue'),
  },
];
export default routes;
