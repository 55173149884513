import { createI18n } from 'vue-i18n';
import fr from './locales/fr';
import en from './locales/en';

const i18n = createI18n({
  locale: navigator.language.split('-'),
  fallbackLocale: navigator.language.split('-') || 'en',
  messages: {
    fr,
    en,
  },
});

export default i18n;
