export default [
  // - Dashboard
  {
    name: 'registration-pos',
    path: '',
    meta: { requireAuth: false },
    component: () => import('../views/register/RegistrationPosView.vue'),
  },
  {
    name: 'registration-profile',
    path: 'registration-profile',
    meta: { requireAuth: false },
    component: () => import('../views/register/RegistrationProfileView.vue'),
  },

];
