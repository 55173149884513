<template>
    <v-card>
      <v-layout>
        <v-app-bar>
          <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" :class="drawer? 'open': 'close'"></v-app-bar-nav-icon>
  
          <div class="v-list-item__content--logo">
          <img src="@images/logo.svg" alt="logo dior" />
        </div>
        <div class="language-select">
        <v-select
                v-model="langSelected" label="" :items="optionLocal" variant="underlined" 
                @update:model-value="setLocale(langSelected)"></v-select>
      </div>
        </v-app-bar>
  
        <v-navigation-drawer v-model="drawer" location="top" temporary>
          <p>Menu</p>
          <v-list>
             <v-list-item v-for="navLink in items" :key="navLink.title" :title="navLink.title" :to="navLink.href"></v-list-item>
          </v-list>
         
          <!-- <v-list :items="items"></v-list> -->
        </v-navigation-drawer>
  
      </v-layout>
    </v-card>
  </template>
  
  <script>

    export default {
      data: () => ({
        drawer: null,
        group: null,
        lang:'',
        navigLanguage:navigator.language.split('-') ,
        langSelected: '',
        optionLocal:['fr', 'en'],
        arrayLocal:[],
        items: [
          {
            title: 'My points of sales',
            value: 'my-points-sales',
            href: '/my-points-sales',
          },
            
          {
            title: 'Moderation page',
            value: 'moduration',
            href: '/learners-moderation',
          },
          {
            title: 'Team management page',
            value: 'teamManager',
            href: '/team-manager',
         
          },
          {
            title: 'Archived learners',
            value: 'archived-learners',
            href: '/archived-learners',
          
          },
        ],
      }),
  
      watch: {
        group() {
          this.drawer = false
        },
      },
      created() {
        if(this.navigLanguage=='fr'){
          this.langSelected='fr';
          this.$route.params.lang='fr';
          this.$i18n.locale='fr';

        }
        else if(this.navigLanguage=='en'){
          this.langSelected='en';
          this.$route.params.lang='en';
          this.$i18n.locale='en';
        }
        else{
          this.langSelected='en';
          this.$route.params.lang='en';
          this.$i18n.locale='en';
        }
       
        this.arrayLocal=this.optionLocal;
        this.optionLocal= this.optionLocal.filter((item) => item != this.langSelected)
        this.submitLang(this.$i18n.locale);
        this.lang=this.$route.params.lang == 'fr'? 'fr': 'en'
        this.items[0].title = this.lang == 'fr'?'Mes points de vente':'My points of sales';
        this.items[1].title = this.lang == 'fr'?'En attente de validation':'Learners waiting for moderation';
        this.items[2].title = this.lang == 'fr'?"Gestion de l'équipe":"Team management";
        this.items[3].title = this.lang == 'fr'?'Apprenants archivés':'Archived learners';
      },
      methods:{
        submitLang(lang){
           let target={
               'lang':lang=='fr'?'fr-FR':'en-US',
                'request':'submit_lang'
            };
            const options = target;
             this.$services.registerResources.SubmitRegisterForm(options).then((response) => {
              console.log(response.data);
              
                })
                .catch(({ error }) => {
                    console.log(error)
                })
    },
    setLocale(locale) {
        this.$i18n.locale = locale
        localStorage.setItem('lang', locale);
        this.optionLocal= this.optionLocal.filter((item) => item != locale);
        if(this.langSelected !== locale){
          this.optionLocal=this.arrayLocal.filter((item) => item == this.langSelected);
        }else{
          this.optionLocal=this.arrayLocal.filter((item) => item !== this.langSelected);
        }
        this.$router.push({
            params: { lang: locale }
        })
        this.submitLang(locale)
    }
      }
    }
  </script>
  