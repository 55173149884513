const getters = {
  /**
   * get session
   */
  session: (state) => state.session,
  getName:(state) => state.name,
  getLegalUrle:(state) => state.legal_url
};

export default getters;
