import Resource from './resource';

class register extends Resource {
  constructor() {
    super('');
  }
  ListCountry(options) {
    
    return this.post('admin/country/search',options);
  }
  ListRetailer(options) {
    return this.post('admin/retailer/search',options);
  }
  ListPointsSale(options) {
    return this.post('admin/pos/search',options);
  }
  ListSalesRep(options) {
    return this.post('admin/salesrep/search',options);
  }
  SubmitRegisterForm(options) {
    return this.postForm('formulaire',options);
  }
}
export default new register();
