/**
 * My plugins
 */

import axios from './axios';
import services from './services';
import vuetify from './vuetify';

export default {
  install(app) {
    axios(app);
    services(app);
    vuetify(app);
  
  },
};
