import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes';
const router = createRouter({
  linkActiveClass: 'active',
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {};
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = 'smooth';
    return scroll;
  },
});
router.beforeEach((to) => {
  to.meta.loading = true;
});
router.afterEach((to) => {
  to.meta.loading = false;
});
const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
export default router;
