
import { CHANGE_SESSION } from './mutation-types';

/**
 * @type {import('vuex/types').ActionTree<typeof import('./state').default>}
 */
const actions = {
 
};

export default actions;
