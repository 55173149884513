import App from '@/App.vue'
import router from '@/router'
import './bootstrap';
import.meta.glob(['../images/**']);
import '@/assets/scss/style.scss';
import plugins from './plugins';
import store from './store';
// Vuetify
import '@mdi/font/css/materialdesignicons.css'
import i18n from './i18n';
import { createApp } from 'vue'


createApp(App)
    .use(router)
    .use(i18n)
    .use(store)
    .use(plugins)
    .mount('#app')
