/**
 * Custom axios instance
 */

import axios from 'axios'

export default axios.create({
    baseURL: import.meta.env.VITE_API_URL,
    timeout: 30 * 1000, // 3s
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
    withCredentials: true,
})
