import {

  registerService,learnersInformationsService

} from '../services';

export default (app) => {
  // alias
  const services = { 
    registerResources: registerService,
    learnersInformationsResources:learnersInformationsService
    
  };

  // // mount the services to Vue
  // Object.defineProperties(Vue, {
  //   services: { get: () => services }
  // })

  // mount the services to Vue component instance
  app.config.globalProperties.$services = services;
};
